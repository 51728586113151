import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { useQueryParam } from "~/utils/hooks/useQueryParam";
import { toInt } from "~/utils/jsUtils";
import { useExternalLivestream } from "~/screens/external/stream/useExternalStream";
import { useLivestreamTask } from "~/screens/app/detection/livestreams/data/useLivestreamsStatus";
import AppPageSpinner from "~/layouts/app/AppPageSpinner";
import { ErrorNotification } from "~/components/notification/ErrorNotification";
import Icon from "~/components/icons/Icon";
import { formatDateTime } from "~/utils/dateUtils";
import Notification from "~/components/notification/Notification";
import { LivestreamView } from "~/screens/app/detection/livestreams/livestream/LivestreamView";
import { AppRoute } from "~/config/Routes";
import LinkButton from "~/components/button/LinkButton";
import { backIcon } from "~/components/button/IconButton";

interface ExternalLivestreamViewProps {}

export function ExternalLivestreamView(props: ExternalLivestreamViewProps) {
  const id = useQueryParam("id", toInt);
  const code = useQueryParam("code");
  const project = useQueryParam("project", toInt);
  const { data: stream, isLoading: l1 } = useExternalLivestream(id, code);
  const { data: task, isLoading: l2 } = useLivestreamTask(id, stream?.server);
  const goBackUrl = useMemo(() => {
    if (!project) return undefined;
    return AppRoute.ExternalLivestreams.create(project, code);
  }, [project, code]);
  if (l1 || l2) return <AppPageSpinner />;
  if (!stream)
    return (
      <ErrorNotification>
        Failed to get the stream. This could be for technical reasons or because
        you do not have the necessary authorizations.
      </ErrorNotification>
    );
  return (
    <div>
      {goBackUrl && (
        <ButtonRow>
          <LinkButton
            url={goBackUrl}
            icon={backIcon}
            target={"_self"}
            variant={"is-link"}
          >
            Back to overview
          </LinkButton>
        </ButtonRow>
      )}
      <h1>{stream.title}</h1>
      {!task && (
        <Info>
          <Icon icon={faClock} /> Planned start:{" "}
          {formatDateTime(stream.plannedStart)}
        </Info>
      )}
      {!task && (
        <Notification type={"info"}>
          No video detection is currently active for this stream. As soon as a
          live broadcast begins, this page updates automatically.
        </Notification>
      )}
      {task && (
        <LivestreamView server={stream.server} task={task} stream={stream} />
      )}
    </div>
  );
}

const Info = styled.div`
  display: inline-block;
  margin-bottom: 24px;
  background-color: white;
  padding: 14px;
  border: 1px solid rgba(24, 28, 33, 0.06);
  border-radius: 6px;
`;

const ButtonRow = styled.div`
  margin-bottom: 16px;
`;
